import React from "react";
import CampaignLogo from '../images/campaign5.png';
import Enjoy from '../images/ENJOY-NGO-SITO.png';
import Logo1 from '../images/en_IGP_bw.png';
import Logo2 from '../images/en_AOP_bw.png';
import "./styles.css";

const NavBar = {
  background: '#ccc',
  borderRadius: 0,
  border: 0,
  color: 'white',
  height: 75,
  padding: '20px 30px',
  boxShadow: 'none',
  textTransform: 'capitalize',
  display: 'flex',
  justifyContent: "space-between",
  flexDirection: 'row',
  maxWidth: 'none'

}

// const NavBar = styled('div')({
//   background: '#ccc',
//   borderRadius: 0,
//   border: 0,
//   color: 'white',
//   height: 75,
//   padding: '20px 30px',
//   boxShadow: 'none',
//   textTransform: 'capitalize',
//   display: 'flex',
//   justifyContent: "space-between",
//   flexDirection: 'row',
//   maxWidth: 'none'

// });

const Campaign = {
  color: 'white',
  background: 'black',
  padding: '1px',
  fontSize: '10px',
  width: '100%',
  textAlign: 'left',
  maxHeight: '40px'
}

const CampaignLogoContainer = {
  padding: '0px 3%',
  width: '100%',
  marginTop: '-15px'
};

const EnjoyLogoContainer = {

  marginTop: "-62px",
  marginRight: "1%",
  minWidth: "81px",
  float: "right",
  position: "relative",
  zIndex: "11",
  paddingTop: "0px"
};
const Center = {

  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
  alignItems: 'center'
}
const Tagline = {

  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
  alignItems: 'center',
  color: 'white'
}
const FooterContainer = {
  position: 'fixed',
  width: '100%'

}

const Para = {

  padding: '0px 5% 0px 5%',
  fontFamily: 'Helvetica, Sans-Serif',
  marginTop: '2px'
  // lineHeight: '16px',
  // fontSize: '10px'

};
const NewIndexPage = () => {

  return (
    <>
      <div className="wrap">
        <nav className="flex justify-between nav arca18 dt w-100 border-box pa4 ph5-ns"><a aria-current="page"  >

          <img src="https://canada.nativegrapeodyssey.com/wp-content/uploads/2019/06/NGO_LOGO_White-1.svg" className="dib w4" alt="Native Grape Odyssey" /></a><input className="navicon" id="navicon" type="checkbox" />
          <div className="tagline" style={Tagline}>EUROPE. QUALITY. WINE.</div>
        </nav>
        
		<div style={Center} id="wrappers" className="ma4n">
		<a href="https://tasting.nativegrapeodyssey.com">
			<p class="arca57">Join the App</p>
			</a>
		</div>
		
        <div style={Center} id="wrappers" className="ma4n"><a href="https://canada.nativegrapeodyssey.com">
          <p className="arca58">CANADA</p>
        </a><a href="https://japan.nativegrapeodyssey.com">
            <p className="arca58">JAPAN / 日本</p>
          </a><a href="https://russia.nativegrapeodyssey.com">
            <p className="arca58">RUSSIA / РОССИЯ</p>
          </a></div>

      </div>
      <Footer />
    </>
  );
};

const Footer = (props) => {

  return (
    <div className="footer" style={FooterContainer} >
      <div style={Campaign} className=" " id="campaign">

        <p style={Para} className=" " id="campaigntext">The contents of this promotional campaign represents the views of the author only and is his/her sole responsibility. The European Commission   and the Consumers, Health, Agriculture and Food Executive Agency (CHAFEA) do not accept any responsibility for any use of the information it contains.</p></div>

      <div style={NavBar}  >

        <div style={CampaignLogoContainer}>
          <img class="CampaignLogo" id="EU_campaign" src={CampaignLogo} alt="Native Grape Odyssey" />
          <img class="Logos" src={Logo1} alt="Native Grape Odyssey" />
          <img class="Logos" src={Logo1} alt="Native Grape Odyssey" />
        </div>

        <div style={EnjoyLogoContainer}>
          <img id="Enjoy" src={Enjoy} alt="Native Grape Odyssey" />
        </div>
      </div>

    </div>

  )
}

export default NewIndexPage;
